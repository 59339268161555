import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ spacing, palette, breakpoints }) => ({
  root: {
    display: 'flex',
  },
  topBar: {
    width: '100%',
    margin: 0,
    padding: 0,
    zIndex: 999,
    position: 'fixed',
    maxWidth: 'none',
    borderBottom: '1px solid #333',
    backgroundColor: '#1f1f1f !important',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  mainContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 100,
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    boxShadow: 'rgb(239 239 239 / 6%) 0px 0px 8px 2px',
    backgroundColor: '#262626',
    borderRadius: 10,
    padding: 20,
    maxWidth: 600,
    margin: '0 auto',
    minHeight: 340,
    minWidth: 600,
  },
  boxHeader: {
    display: 'flex',
    height: 80,
    width: '100%',
  },
  boxContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 40,
  },
  bck: {
    height: 340,
    width: 600,
    marginBottom: 40,
  },
  content: {
    display: 'block',
    height: 340,
    width: 600,
  },
  boxFooter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  divider: {
    borderWidth: '1px !important',
    width: '80%',
    marginBottom: 20,
  },
  title: {
    fontSize: 10,
    color: '#fff',
    fontWeight: 'bold',
    marginBottom: 20,
  },
  copylink: {
    fontSize: 10,
    color: '#fff',
    fontWeight: 'bold',
    marginBottom: 20,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },
  player: {
    marginBottom: 40,
  },
  link: {
    color: '#ccc',
    fontWeight: 'normal',
  },
  copy: {
    marginLeft: 4,
    fontSize: 10,
  },
  input: {
    width: '80%',
    border: '1px solid transparent',
    height: 42,
    position: 'relative',
    minHeight: 42,
    borderRadius: 24,
    backgroundColor: '#444',
    padding: '2px 15px',
    marginBottom: 15,
    color: '#fff',
    fontWeight: 'bold',
    '&:focus': {
      outline: 'none',
    },
  },
  button: {
    padding: '12px 34px !important',
    borderRadius: 25,
    height: 48,
    fontSize: 19,
    minWidth: 190,
    fontWeight: 'bold',
    lineHeight: 1.26,
    textTransform: 'none',
    color: '#fff',
    backgroundColor: '#FC223B',
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: '#FC4156',
    },
  },
}));

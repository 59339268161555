export default {
  fontFamily: "'Roboto', sans-serif",
  fontWeightLight: undefined,
  fontWeightRegular: 400,
  fontWeightMedium: undefined,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  fontWeightExtraBold: 800,
  // remove Mui styling
  h4: null,
  h5: null,
  h6: null,
  caption: {
    fontSize: '0.875rem',
  },
  overline: null,
  body1: null,
  body2: null,
  button: null,
};

import { createTheme } from '@mui/material/styles';

import shadows from './shadows';
import typography from './typography';

export const palette = {
  primary: {
    main: '#18a0fb',
    medium: '#74C6FD',
    light: '#D1ECFE',
  },
  common: {
    white: '#FFF',
    black: '#000',
  },
  secondary: {
    main: '#FDC564',
    medium: '#FFDFA2',
    light: '#FFF4E0',
  },
  accent: {
    main: '#FA4969',
  },
  background: {
    default: '#EFF7FF',
    contrast: '#031839',
    light: '#CEE2FF',
    primary: '#18a0fb',
    disabled: '#aaaaaa',
    lightest: '#FBFDFF',
  },
  neutral: {
    dark: '#354661',
    medium: '#687488',
    medium2: '#9AA3B0',
    light: '#CDD1D7',
  },
  text: {
    default: '#031839',
    contrast: '#FFFFFF',
    primary: '#18a0fb',
  },
  chat: {
    bubble: {
      user: {
        background: '#FFFFFF',
        text: '#031839',
        border: '#FDC564',
      },
      scoopr: {
        background: '#FFFFFF',
        text: '#031839',
      },
      loading: {
        default: '#18a0fb',
      },
    },
    button: {
      default: {
        background: '#FDC564',
        text: '#031839',
      },
      transparent: {
        border: '#031839',
        text: '#031839',
      },
    },
    input: {
      border: '#18A0FB',
    },
  },
  success: {
    main: '#56BA82',
  },
  error: {
    main: '#FA4969',
  },
};

const { palette: defaultPalette, typography: defaultTypography } =
  createTheme();
// A custom theme for this app
const theme = createTheme({
  palette: { ...defaultPalette, ...palette },
  shadows,
  typography: { ...defaultTypography, ...typography },
  components: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});
export default theme;

import 'react-html5video/dist/styles.css';
import './App.css';
import {
  Container,
  Divider,
  Toolbar,
  AppBar,
  Button,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import ReactHlsPlayer from 'react-hls-player';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import clsx from 'clsx';

import useStyles from './App.style';
import Logo from './components/Logo';
import bck from './assets/images/bck1.png';

function App() {
  const classes = useStyles();
  const [source, setSource] = useState('');
  const [inputSource, setInputSource] = useState('');
  const [play, setPlay] = useState(false);
  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.topBar}>
        <Toolbar>
          <Container className={classes.container}>
            <Logo />
          </Container>
        </Toolbar>
      </AppBar>
      <Container className={classes.mainContent}>
        <div className={classes.box}>
          <Typography variant="p" className={classes.title}>
            Video{play ? ':' : ''} <i className={classes.link}>{source}</i>
          </Typography>
          {play ? (
            <div className={classes.boxContent}>
              <div className={classes.content}>
                <ReactHlsPlayer
                  src={source}
                  autoPlay={true}
                  controls={true}
                  width="100%"
                  height="auto"
                />
              </div>
            </div>
          ) : (
            <img src={bck} className={classes.bck} alt="video" />
          )}
          <div className={classes.boxFooter}>
            <Divider
              className={clsx(classes.divider, play ? classes.player : null)}
            />
            {!play ? (
              <Typography
                variant="p"
                className={classes.copylink}
                onClick={() =>
                  setInputSource(
                    'https://h3wdblc3ocj2af.data.mediastore.eu-west-1.amazonaws.com/gettr/obs.m3u8',
                  )
                }
              >
                Demo link:{' '}
                <i className={classes.link}>
                  https://h3wdblc3ocj2af.data.mediastore.eu-west-1.amazonaws.com/gettr/obs.m3u8
                </i>
                <ContentCopyIcon className={classes.copy} />
              </Typography>
            ) : null}

            {!play ? (
              <input
                className={classes.input}
                placeholder="Media url"
                value={inputSource}
                onChange={e => {
                  setInputSource(e.target.value);
                }}
              />
            ) : null}

            {!play ? (
              <Button
                className={classes.button}
                variant="contained"
                onClick={() => {
                  setSource(inputSource);
                  setPlay(true);
                }}
              >
                Play
              </Button>
            ) : (
              <Button
                className={classes.button}
                variant="contained"
                onClick={() => {
                  setInputSource('');
                  setSource('');
                  setPlay(false);
                }}
              >
                Change media
              </Button>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default App;

import React from 'react';
import { SvgIcon } from '@mui/material';

const LogoIcon = ({ ...props }) => (
  <SvgIcon
    width="700"
    height="314"
    viewBox="0 0 700 314"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_6474_463936)">
      <path
        d="M377.286 46.365C386.381 60.7078 391.979 74.7008 390.054 87.6443C386.381 112.832 354.547 129.973 348.075 145.365C324.987 121.752 310.819 105.66 308.545 90.6178C305.047 62.8068 344.927 40.0681 348.075 8.75879C355.247 17.8542 357.346 33.5964 357.346 42.6918C360.844 38.1441 362.943 32.372 362.943 26.5999C369.765 34.6458 371.864 48.6388 371.864 59.1336C375.187 55.8103 377.111 51.2625 377.286 46.365Z"
        fill="#FF0000"
      />
      <path
        d="M201.848 190.667H266.741V161.106H162.843V302.436H266.741V273.225H201.848V246.639H258.695V217.253H201.848V190.667Z"
        fill="white"
      />
      <path
        d="M429.41 161.106V190.667H466.492V302.436H505.497V190.667H542.229V161.106H429.41Z"
        fill="white"
      />
      <path
        d="M668.341 252.586C678.311 248.038 686.357 240.342 691.254 230.547C696.152 220.752 697.551 209.557 695.277 198.888C693.003 188.218 687.056 178.598 678.486 171.776C669.915 164.955 659.42 161.106 648.401 161.106C621.289 161.106 594.178 161.106 567.066 161.106C567.066 209.032 567.066 257.133 567.066 305.059C580.185 305.059 593.128 305.059 606.247 305.059C606.247 288.968 606.247 272.876 606.247 256.784C612.544 256.784 618.841 256.784 625.137 256.784C634.932 272.876 644.553 288.968 654.348 305.059C669.565 305.059 684.783 305.059 700 305.059C689.505 287.568 679.01 270.077 668.341 252.586ZM651.899 221.976C648.401 225.474 643.853 227.398 638.955 227.398C628.111 227.398 617.266 227.398 606.247 227.398C606.247 215.154 606.247 202.911 606.247 190.667C617.091 190.667 627.936 190.667 638.955 190.667C643.853 190.667 648.576 192.591 651.899 196.089C655.397 199.587 657.321 204.135 657.321 209.032C657.321 213.93 655.397 218.478 651.899 221.976Z"
        fill="white"
      />
      <path
        d="M367.666 190.667H395.827L413.668 161.106H282.483L300.324 190.667H328.485V302.436H367.666V190.667Z"
        fill="white"
      />
      <path
        d="M129.785 294.39C114.218 301.037 97.2514 305.06 80.11 305.06C68.2159 305.06 56.3218 303.136 45.4773 298.763C38.8306 295.964 32.5337 292.116 27.1114 287.568C21.5142 282.846 16.7916 277.423 12.7686 271.476C8.74563 265.529 5.59719 258.883 3.32333 252.061C1.04946 245.24 0 237.893 0 230.722C0 216.204 4.3728 201.861 12.4188 189.792C20.4648 177.723 32.009 168.278 45.4773 162.681C52.2989 159.882 59.1204 158.133 66.4668 157.259C70.1399 156.734 73.6382 156.559 77.3113 156.384C80.9845 156.209 84.4828 156.384 88.1559 156.559C102.674 157.608 116.842 161.282 130.135 167.054C124.013 177.199 117.891 187.343 111.769 197.314C101.974 192.241 91.3043 189.442 80.2848 189.442C68.3908 189.442 57.1964 194.515 49.3253 203.435C41.4543 212.356 37.956 224.25 39.3553 235.969C40.9295 247.688 47.4013 258.183 57.1964 264.83C66.9915 271.476 79.2354 273.4 90.7796 270.427C90.7796 256.084 90.7796 241.741 90.7796 227.398C103.898 227.398 116.842 227.398 129.96 227.398C129.785 249.787 129.785 272.001 129.785 294.39Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_6474_463936">
        <rect
          width="700"
          height="312.72"
          fill="white"
          transform="translate(0 0.758789)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default LogoIcon;
